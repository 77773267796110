div.button {
  justify-content: space-between;
  font-weight: normal;
}

button.root {
  &:hover {
    background: none;
  }

  &:hover .button {
    color: var(--color-text-dark);
  }

  &.open,
  &:focus-visible {
    border-color: var(--color-brand);
    outline: none;
  }
}
